import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteApi, getApi, postApi, putApi } from "../config/configAxios";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TransitionsModal from "../components/Modal";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react/dist/iconify.js";

const ThanaList = () => {
  const [thanas, setThanas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [openStore, setOpenStore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = () => {
    getApi("/bit-thanas")
      .then((response) => {
        setThanas(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the thanas!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const handleDelete = (id) => {
    deleteApi(`/bit-thanas/${id}`)
      .then((response) => {
        setThanas(thanas.filter((thana) => thana.id !== id));
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.error("There was an error deleting the thana!", error);
      });
  };

  /// edit update
  useEffect(() => {
    if (id) {
      getApi(`/bit-thanas/${id}`)
        .then((response) => {
          setName(response.data.name);
        })
        .catch((error) => {
          console.error("There was an error fetching the thana!", error);
        });
    }
  }, [id]);
  const onClose = () => {
    setOpenStore(false);
    setId("");
    setName("");
    setIsSubmitting(false);
  };
  const onOpen = () => {
    setOpenStore(true);
    setId("");
    setName("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const thanaData = { name };
    setIsSubmitting(true);

    if (id) {
      putApi(`/bit-thanas/${id}`, thanaData)
        .then((response) => {
          toast.success("Updated successfully");
        })
        .catch((error) => {
          console.error("There was an error updating the thana!", error);
        });
    } else {
      postApi("/bit-thanas", thanaData)
        .then((response) => {
          toast.success("Added successfully");
        })
        .catch((error) => {
          console.error("There was an error creating the thana!", error);
        });
    }
    fetchData();
    onClose();
  };

  return (
    <AdminLayout title={"Thanas List"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Thana</h1>
            <div>
              <button className="btn-indigo" onClick={() => onOpen()}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Name</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {thanas.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item.name}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => {
                            setId(item.id);
                            setName(item.name);
                            setOpenStore(true);
                          }}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* modal  */}

      <TransitionsModal
        title={id ? "Edit Thana" : "New Thana"}
        open={openStore}
        setOpen={setOpenStore}
      >
        <form className="" onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                required
              />
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? (
                  <BeatLoader color="#fff" />
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default ThanaList;
