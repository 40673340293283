import React, { useEffect, useState } from "react";
import { getApi, postApi, putApi, deleteApi } from "../config/configAxios";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import TransitionsModal from "../components/Modal";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import JoditEditor from "jodit-react";
import { config } from "../utils/JoditConfig";
import { useAuthorize } from "../hooks/useAuthorize";
import { Link } from "react-router-dom";
import { shortenedText } from "../utils/textClamp";

const BitNews = () => {
  const hasPermission = useAuthorize();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    file: null,
    lat: "",
    long: "",
    bit_thana_id: "",
    content: "",
  });
  const [id, setId] = useState("");
  const [openStore, setOpenStore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [thanas, setThanas] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchData = async () => {
    try {
      const response = await getApi("/bit-news");
      setData(response.data);
    } catch (error) {
      toast.error("There was an error fetching the data!");
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchThanas = () => {
    getApi("/bit-thanas")
      .then((response) => {
        setThanas(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the thanas!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchThanas();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteApi(`/bit-news/${id}`);
      setData(data.filter((officer) => officer.id !== id));
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("There was an error deleting the officer!");
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const response = await getApi(`/bit-news/${id}`);
          setFormData({
            title: response.data.title,
            bit_thana_id: response.data.bit_thana_id,
            content: response.data.content,
            id: response.data.id,
          });
        } catch (error) {
          toast.error("There was an error fetching the officer!");
          console.error("There was an error fetching the officer!", error);
        }
      })();
    }
  }, [id]);
  const onClose = () => {
    setOpenStore(false);
    setId("");
    setFormData({
      title: "",
      file: null,
      lat: "",
      long: "",
      bit_thana_id: "",
      content: "",
    });
    setIsSubmitting(false);
  };

  const onOpen = () => {
    setOpenStore(true);
    setId("");
    setFormData({
      title: "",
      file: null,
      lat: "",
      long: "",
      bit_thana_id: "",
      content: "",
    });
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  // geolocation
  //   const getLocation = async () => {
  //     if (!navigator.geolocation) {
  //       toast.error("Geolocation is not supported by your browser.");
  //       return;
  //     }
  //     const options = {
  //       enableHighAccuracy: true,
  //       timeout: 5000,
  //       maximumAge: 0,
  //     };
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         console.log(latitude, longitude);
  //         setFormData({ ...formData, lat: latitude, long: longitude });
  //       },
  //       () => {
  //         toast.error("Unable to retrieve your location.");
  //       },
  //       options
  //     );
  //   };

  //   const getLocation = async () => {
  //     return new Promise((resolve, reject) => {
  //       if (!navigator.geolocation) {
  //         toast.error("Geolocation is not supported by your browser.");
  //         reject(new Error("Geolocation is not supported by your browser."));
  //       } else {
  //         const options = {
  //           enableHighAccuracy: true,
  //           timeout: 5000,
  //           maximumAge: 0,
  //         };
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             const { latitude, longitude } = position.coords;
  //             resolve({ latitude, longitude });
  //           },
  //           (error) => {
  //             toast.error("Unable to retrieve your location.");
  //             reject(error);
  //           },
  //           options
  //         );
  //       }
  //     });
  //   };
  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        toast.error("Geolocation is not supported by your browser.");
        reject(new Error("Geolocation is not supported by your browser."));
      } else {
        const options = {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        };
        const watchId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            resolve({ latitude, longitude });
            navigator.geolocation.clearWatch(watchId);
          },
          (error) => {
            toast.error("Unable to retrieve your location.");
            reject(error);
          },
          options
        );
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    try {
      const location = await getLocation();

      if (id) {
        const formDataValue = new FormData();
        Object.keys(formData).forEach((key) => {
          formDataValue.append(key, formData[key]);
        });
        formDataValue.append("_method", "PUT");
        await postApi(`/bit-news/${id}`, formDataValue);
        toast.success("Updated successfully");
      } else {
        const formDataValue = new FormData();
        const updatedFormData = {
          ...formData,
          lat: location.latitude,
          long: location.longitude,
        };
        Object.keys(updatedFormData).forEach((key) => {
          formDataValue.append(key, updatedFormData[key]);
        });
        formDataValue.append("user_id", user?.id);

        await postApi("/bit-news", formDataValue);
        toast.success("Added successfully");
      }
      onClose();
      fetchData();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        toast.error(
          `There was an error ${id ? "updating" : "creating"} the officer!`
        );
        console.error(
          `There was an error ${id ? "updating" : "creating"} the officer!`,
          error
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const canEditOrDelete = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffMinutes = (currentDate - createdDate) / (1000 * 60);
    return diffMinutes <= 30;
  };

  return (
    <AdminLayout title={"Bit News"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Bit News</h1>
            <div>
              {hasPermission("Bit Officer") && (
                <button className="btn-indigo" onClick={onOpen}>
                  Add new
                </button>
              )}
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Image</th>
                  <th className="table-h">Title</th>
                  <th className="table-h">Thana</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">
                      {item.file && (
                        <img
                          className="w-12 h-12 rounded object-cover"
                          src={`${process.env.REACT_APP_API_FILE_URL}${item.file}`}
                          alt=""
                        />
                      )}
                    </td>
                    <td className="table-b line-clamp-2">{shortenedText(item.title, 30)}</td>
                    <td className="table-b">{item.bit_thana?.name}</td>
                    <td className="table-b">
                      <Box display="flex">
                        {hasPermission("Admin") ||
                        canEditOrDelete(item.created_at) ? (
                          <>
                            <IconButton
                              className="mr-3"
                              onClick={() => handleDelete(item.id)}
                            >
                              <Delete className="text-red-500" />
                            </IconButton>
                            <IconButton
                              className="mr-3"
                              onClick={() => {
                                setId(item.id);
                                setFormData(item);
                                setOpenStore(true);
                              }}
                            >
                              <Icon
                                icon={"bxs:edit"}
                                className="text-blue-500"
                              />
                            </IconButton>
                          </>
                        ) : null}
                        {hasPermission("Admin") && (
                          <Link
                            to={`https://maps.google.com?q=${item.lat},${item.long}`}
                            target="_blank"
                          >
                            <IconButton className="mr-3">
                              <Icon icon={"bxs:map"} className="text-red-500" />
                            </IconButton>
                          </Link>
                        )}
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Modal */}
      <TransitionsModal
        title={id ? "Edit News" : "New News"}
        open={openStore}
        setOpen={setOpenStore}
      >
        <form onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            {[
              { label: "Title", name: "title", type: "text" },
              { label: "File", name: "file", type: "file" },
              { label: "Thana ID", name: "bit_thana_id", type: "text" },
              { label: "Content", name: "content", type: "text" },
            ].map(({ label, name, type, optional }) =>
              name === "content" ? (
                <div className="md:col-span-2" key={name}>
                  <label htmlFor={name} className="font-semibold text-sm">
                    {label} {optional ? "(optional)" : ""}
                  </label>
                  <JoditEditor
                    config={config}
                    tabIndex={1}
                    value={formData[name]}
                    onBlur={(newContent) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        content: newContent,
                      }))
                    }
                  />
                  {errors[name] && (
                    <span className="text-red-500 text-sm">
                      {errors[name][0]}
                    </span>
                  )}
                </div>
              ) : name === "bit_thana_id" ? (
                <div className="md:col-span-1" key={name}>
                  <label htmlFor={name} className="font-semibold text-sm">
                    {label} {optional ? "(optional)" : ""}
                  </label>
                  <select
                    id="bit_thana_id"
                    name="bit_thana_id"
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option className="p-2 rounded-md bottom-0" value="">
                      select
                    </option>
                    {thanas?.map((item) => (
                      <option
                        key={item.id}
                        selected={item?.id === formData?.bit_thana_id}
                        className="p-2 font-semibold rounded-md bottom-0"
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors[name] && (
                    <span className="text-red-500 text-sm">
                      {errors[name][0]}
                    </span>
                  )}
                </div>
              ) : (
                <div className="md:col-span-1" key={name}>
                  <label htmlFor={name} className="font-semibold text-sm">
                    {label} {optional ? "(optional)" : ""}
                  </label>
                  <input
                    type={type}
                    id={name}
                    name={name}
                    value={name !== "file" ? formData[name] : undefined}
                    onChange={handleChange}
                    className="form-control"
                    required={name === "file" ? !id && !optional : true}
                  />
                  {errors[name] && (
                    <span className="text-red-500 text-sm">
                      {errors[name][0]}
                    </span>
                  )}
                </div>
              )
            )}
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? (
                  <BeatLoader color="#fff" />
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default BitNews;
