import React, { useEffect, useState } from "react";
import { deleteApi, getApi, postApi, putApi } from "../config/configAxios";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import { Box, IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import TransitionsModal from "../components/Modal";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { Icon } from "@iconify/react/dist/iconify.js";

const BitOfficer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    address: "",
    current_address: "",
    thana: "",
    mobile: "",
    email: "",
    image: null, // Use null for file inputs to handle them correctly
    phone: "",
    fax: "",
    map_location: "",
  });
  const [id, setId] = useState("");
  const [openStore, setOpenStore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const response = await getApi("/bit-officers");
      setData(response.data);
    } catch (error) {
      toast.error("There was an error fetching the data!");
      console.error("There was an error fetching the data!", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteApi(`/bit-officers/${id}`);
      setData(data.filter((item) => item.id !== id));
      toast.success("Deleted successfully");
    } catch (error) {
      toast.error("There was an error deleting the officer!");
      console.error("There was an error deleting the officer!", error);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const response = await getApi(`/bit-officers/${id}`);
          setFormData({
            name: response.data.name,
            designation: response.data.designation,
            address: response.data.address,
            current_address: response.data.current_address,
            thana: response.data.thana,
            mobile: response.data.mobile,
            email: response.data.email,
            phone: response.data.phone,
            fax: response.data.fax,
            map_location: response.data.map_location,
            id: response.data.id,
          });
        } catch (error) {
          toast.error("There was an error fetching the officer!");
          console.error("There was an error fetching the officer!", error);
        }
      })();
    }
  }, [id]);

  const onClose = () => {
    setOpenStore(false);
    setId("");
    setFormData({
      name: "",
      designation: "",
      address: "",
      current_address: "",
      thana: "",
      mobile: "",
      email: "",
      image: null,
      phone: "",
      fax: "",
      map_location: "",
    });
    setErrors({});
    setIsSubmitting(false);
  };

  const onOpen = () => {
    setOpenStore(true);
    setId("");
    setFormData({
      name: "",
      designation: "",
      address: "",
      current_address: "",
      thana: "",
      mobile: "",
      email: "",
      image: null,
      phone: "",
      fax: "",
      map_location: "",
    });
    setErrors({});
  };

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    const formDataValue = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value) {
        formDataValue.append(key, value);
      }
    });

    try {
      if (id) {
        formDataValue.append("_method", "PUT");
        await postApi(`/bit-officers/${id}`, formDataValue);
        toast.success("Updated successfully");
      } else {
        await postApi("/bit-officers", formDataValue);
        toast.success("Added successfully");
      }
      onClose();
      fetchData();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        toast.error(
          `There was an error ${id ? "updating" : "creating"} the officer!`
        );
        console.error(
          `There was an error ${id ? "updating" : "creating"} the officer!`,
          error
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title={"Officers List"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Officers List</h1>
            <div>
              <button className="btn-indigo" onClick={onOpen}>
                Add new
              </button>
            </div>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Photo</th>
                  <th className="table-h">Name</th>
                  <th className="table-h">Designation</th>
                  <th className="table-h">Mobile</th>
                  <th className="table-h">Email</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">
                      {item.image && (
                        <img
                          className="w-12 h-12 rounded object-cover"
                          src={`${process.env.REACT_APP_API_FILE_URL}${item.image}`}
                          alt=""
                        />
                      )}
                      {!item.image && (
                        <Icon
                          icon={"bx:user"}
                          className="w-12 h-12 text-gray-400"
                        />
                      )}
                    </td>
                    <td className="table-b">{item.name}</td>
                    <td className="table-b">{item.designation}</td>
                    <td className="table-b">{item.mobile}</td>
                    <td className="table-b">{item.email}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => {
                            setId(item.id);
                            setFormData(item);
                            setOpenStore(true);
                          }}
                        >
                          <Edit className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Modal */}
      <TransitionsModal
        title={id ? "Edit Officer" : "New Officer"}
        open={openStore}
        setOpen={setOpenStore}
      >
        <form onSubmit={handleSubmit}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            {[
              { label: "Name", name: "name", type: "text" },
              { label: "Designation", name: "designation", type: "text" },
              { label: "Address", name: "address", type: "text" },
              {
                label: "Current Address",
                name: "current_address",
                type: "text",
              },
              { label: "Thana", name: "thana", type: "text" },
              { label: "Mobile", name: "mobile", type: "text" },
              { label: "Email", name: "email", type: "email" },
              { label: "Image", name: "image", type: "file", optional: true },
              { label: "Phone", name: "phone", type: "text", optional: true },
              { label: "Fax", name: "fax", type: "text", optional: true },
              {
                label: "Map Location",
                name: "map_location",
                type: "text",
                optional: true,
              },
            ].map(({ label, name, type, optional }) => (
              <div className="md:col-span-1" key={name}>
                <label htmlFor={name} className="font-semibold text-sm">
                  {label}{" "}
                  {optional ? (
                    "(optional)"
                  ) : (
                    <span className="text-red-700">*</span>
                  )}
                </label>
                {type === "file" ? (
                  <input
                    type={type}
                    id={name}
                    name={name}
                    onChange={handleChange}
                    className="form-control"
                    accept="image/*"
                  />
                ) : (
                  <input
                    type={type}
                    id={name}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="form-control"
                    required={!optional}
                  />
                )}
                {errors[name] && (
                  <span className="text-red-500 text-sm">
                    {errors[name][0]}
                  </span>
                )}
              </div>
            ))}
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? (
                  <BeatLoader color="#fff" />
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default BitOfficer;
