import axios from "axios";
import store from "../redux/store";

const state = store.getState();
const token = state.auth.token;
if (token) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json"; // Keep this for JSON requests
axios.defaults.headers.post["Accept"] = "application/json";
// Set default Content-Type globally for all requests
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

// Handle API request and response
const handleRequest = async (requestFunction) => {
  try {
    const response = await requestFunction();
    return response;
  } catch (error) {
    throw error;
  }
};

// GET request
export const getApi = async (url) => {
  return handleRequest(() => axios.get(url));
};

// get data use id
export const getApiById = async (url, id) => {
  const urlWithUserId = `${url}?id=${id}`;

  return handleRequest(() => axios.get(urlWithUserId));
};

// POST request
export const postApi = async (url, data) => {
  return handleRequest(() => axios.post(url, data));
};

// PUT request
export const putApi = async (url, data) => {
  return handleRequest(() => axios.post(url, { _method: "PUT", ...data }));
};

// DELETE request
export const deleteApi = async (url) => {
  return handleRequest(() => axios.delete(url));
};
