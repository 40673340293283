import {
  ArrowDownward,
  Close,
  ContactMail,
  Dashboard,
  ForkRightOutlined,
  Home,
  List,
  LocalPolice,
  Man3Rounded,
  WorkHistory,
} from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import { Link, useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import "./sidebar.css";
import { SubMenuCustom } from "./SubMenuCustom";
import { SubMenuItem } from "./SubMenuItem";
import { getApi } from "../../config/configAxios";
import { useAuthorize } from "../../hooks/useAuthorize";

const SideBar = ({ setOpen, open }) => {
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [tableMenu, setTableMenu] = useState([]);
  const hasPermission = useAuthorize();

  useEffect(() => {
    if (!isMdScreen) {
      setOpen(false);
    }
  }, [isMdScreen, setOpen]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const location = useLocation();

  useEffect(() => {
    // Fetch data for table menu items
    const fetchTableMenu = async () => {
      try {
        const response = await getApi("table-menu-item");
        setTableMenu(response?.data?.data);
      } catch (error) {
        console.error("Error fetching table menu items:", error);
      }
    };
    fetchTableMenu();
  }, []);

  const collapsed = false;
  const breakPoint = true;

  return (
    <>
      <Box sx={{ display: "flex" }} position={"relative"}>
        {isMdScreen ||
          (open && (
            <IconButton
              color="inherit"
              aria-label="Open sidebar"
              edge="start"
              onClick={toggleDrawer}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                  position: "absolute",
                  top: "35px",
                  left: "330px",
                  zIndex: 9999,
                },
              }}
            >
              <Close />
            </IconButton>
          ))}
        <Drawer
          sx={{
            zIndex: 1000,
            position: "absolute",
            width: "270px",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "260px",
              boxSizing: "border-box",
              overflowY: "auto",
              borderRight: "0px",
              boxShadow: theme.palette.boxShadow,
            },
            "& .MuiDrawer-paper::-webkit-scrollbar": {
              width: "3px", // Width of the scrollbar
            },
            "& .MuiDrawer-paper::-webkit-scrollbar-track": {
              background: "#f1f1f1", // Track color
            },
            "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
              background: "#888", // Thumb color
            },
            display: isMdScreen ? "block" : open ? "block" : "none", // Show on md and larger screens, hide on xs screens unless open
          }}
          variant="persistent"
          anchor="left"
          open={isMdScreen || open} // Open on md and larger screens and when the open state is true
        >
          <Sidebar width="100%" collapsed={collapsed} breakPoint={breakPoint}>
            <div className="border-b p-5">
              <Link to="https://hossainpurpourashava.gov.bd">
                <img
                  className="max-h-24 m-auto"
                  src={
                    "https://images.vexels.com/media/users/3/142789/isolated/preview/2bfb04ad814c4995f0c537c68db5cd0b-multicolor-swirls-circle-logo.png"
                  }
                  alt=""
                />
              </Link>
            </div>
            {hasPermission("Admin") && (
              <Menu className="min-h-screen bg-white">
                <Link to="/">
                  <MenuItem
                    icon={<Dashboard sx={{ fontSize: "20px" }} />}
                    className={`${
                      location.pathname === "/"
                        ? "bg-blue-50 font-semibold"
                        : ""
                    }`}
                    active={location.pathname === "/"}
                  >
                    Dashboard
                  </MenuItem>
                </Link>
                <Divider />

                <SubMenuCustom
                  label={"Menu section"}
                  icon={<List sx={{ fontSize: "20px" }} />}
                  url={[
                    "/table-menu",
                    "/post",
                    "/menu-item",
                    "/table-menu-post",
                  ]}
                >
                  <Divider />
                  <SubMenuItem title={"Menu"} url={"/menu-item"} />
                  <SubMenuItem title={"Post"} url={"/post"} />
                  <SubMenuItem title={"Table Menu"} url={"/table-menu"} />

                  {tableMenu?.map((item) => (
                    <SubMenuItem
                      key={item.id}
                      title={item?.name}
                      url={`/table-menu-post/${item.id}`}
                    />
                  ))}
                </SubMenuCustom>
                <Divider />

                <SubMenuCustom
                  label={"Home"}
                  icon={<Home sx={{ fontSize: "20px" }} />}
                  url={[
                    "/logo-web-portal",
                    "/top-slider",
                    "/top-banner",
                    "/activity-slider",
                    "/recent-activity",
                  ]}
                >
                  <Divider />
                  <SubMenuItem
                    title={"Recent acitivity"}
                    url={"/recent-activity"}
                  />
                  <SubMenuItem title={"Top slider"} url={"/top-slider"} />
                  <SubMenuItem title={"Latest news"} url={"/latest-news"} />
                  <SubMenuItem
                    title={"Logo web portal"}
                    url={"/logo-web-portal"}
                  />
                  <SubMenuItem title={"Top banner"} url={"/top-banner"} />
                  <SubMenuItem
                    title={"Acitivity slider"}
                    url={"/activity-slider"}
                  />
                </SubMenuCustom>
                <Divider />
                {/* Right bar */}
                <SubMenuCustom
                  label="Right bar"
                  icon={<ForkRightOutlined sx={{ fontSize: "20px" }} />}
                  url={[
                    "/officer",
                    "/rightbar-other",
                    "/sidebar-category",
                    "/sidebar-sub-category",
                  ]}
                >
                  <Divider />
                  <SubMenuItem title={"Officer"} url={"/officer"} />
                  <SubMenuItem
                    title={"Sidebar category"}
                    url={"/sidebar-category"}
                  />
                  <SubMenuItem
                    title={"Sidebar sub category"}
                    url={"/sidebar-sub-category"}
                  />
                  <SubMenuItem title={"Others"} url={"/rightbar-other"} />
                </SubMenuCustom>
                <Divider />
                {/* work section  */}
                <SubMenuCustom
                  label="Work section"
                  icon={<WorkHistory sx={{ fontSize: "20px" }} />}
                  url={[
                    "/video-gallery",
                    "/category",
                    "/sub-category",
                    "/photo-gallery",
                    "/gallery-category",
                  ]}
                >
                  <Divider />
                  <SubMenuItem title={"Category"} url={"/category"} />
                  <SubMenuItem title={"Sub category"} url={"/sub-category"} />
                  <SubMenuItem title={"Notice board"} url={"/notice-board"} />
                  <SubMenuItem title={"Photo gallery"} url={"/photo-gallery"} />
                  <SubMenuItem
                    title={"Gallery category"}
                    url={"/gallery-category"}
                  />
                  <SubMenuItem title={"Video Gallery"} url={"/video-gallery"} />
                </SubMenuCustom>
                <Divider />
                {/* footer section  */}
                <SubMenuCustom
                  label="Footer section"
                  icon={<ArrowDownward sx={{ fontSize: "20px" }} />}
                  url={[
                    "/company-info",
                    "/footer-button",
                    "/footer-heading",
                    "/footer-content",
                  ]}
                >
                  <Divider />
                  <SubMenuItem
                    title={"Footer heading"}
                    url={"/footer-heading"}
                  />
                  <SubMenuItem
                    title={"Footer content"}
                    url={"/footer-content"}
                  />
                  <SubMenuItem
                    title={"Company and Social"}
                    url={"/company-info"}
                  />
                  <SubMenuItem
                    title={"Footer buttons"}
                    url={"/footer-button"}
                  />
                </SubMenuCustom>
                {/* <SubMenuCustom
                  label="Bit Police"
                  icon={<LocalPolice sx={{ fontSize: "20px" }} />}
                  url={["/bit-thanas", "/bit-officers", "/bit-news"]}
                >
                  <Divider />
                  <SubMenuItem title={"Bit Thanas"} url={"/bit-thanas"} />
                  <SubMenuItem title={"Bit Officers"} url={"/bit-officers"} />

                  <SubMenuItem title={"Bit News"} url={"/bit-news"} />
                </SubMenuCustom> */}
                <SubMenuCustom
                  label="Phone Directory"
                  icon={<Man3Rounded sx={{ fontSize: "20px" }} />}
                  url={["/division", "/district", "/phone-directory"]}
                >
                  <Divider />
                  <SubMenuItem title={"Division"} url={"/division"} />
                  <SubMenuItem title={"District"} url={"/district"} />

                  <SubMenuItem
                    title={"Phone Directory"}
                    url={"/phone-directory"}
                  />
                </SubMenuCustom>
                <SubMenuCustom
                  label="Contact"
                  icon={<ContactMail sx={{ fontSize: "20px" }} />}
                  url={["/contact-info", "/contact-messages"]}
                >
                  <Divider />
                  <SubMenuItem
                    title={"Contact Information"}
                    url={"/contact-info"}
                  />
                  <SubMenuItem
                    title={"Contact Messages"}
                    url={"/contact-messages"}
                  />
                </SubMenuCustom>
                <Divider />
              </Menu>
            )}
          </Sidebar>
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
